import React, {useEffect, useState} from "react";
import {useRive, useStateMachineInput} from '@rive-app/react-canvas';

interface RiveComponentProps {
    value: number
    isMobile: boolean
}

const STATE_MACHINE_NAME = 'State Machine 1';

const INPUT_NAME = 'Num. Trigger';

const RiveComponent: React.FC<RiveComponentProps> = ({value, isMobile}) => {
    const RiveStyle: any = {
        opacity: isMobile ? 0.9 : 1,
        position: "absolute",
        top: isMobile ? 150 : -55,
        width: isMobile ? "40vw" : "25vw",
        height: isMobile ? "90vw" : "55vw"
    }

    const {rive: rivLeft, RiveComponent: RiveComponentLeft} = useRive({
        src: 'https://cleaner-web.s3.amazonaws.com/rivs/landing_page_left.riv',
        stateMachines: STATE_MACHINE_NAME,
        autoplay: true,
    });

    const {rive: rivRight, RiveComponent: RiveComponentRight} = useRive({
        src: 'https://cleaner-web.s3.amazonaws.com/rivs/landing_page_right.riv',
        stateMachines: STATE_MACHINE_NAME,
        autoplay: true,
    });

    const rivLeftLevelInput = useStateMachineInput(rivLeft, STATE_MACHINE_NAME, INPUT_NAME);
    const rivRightLevelInput = useStateMachineInput(rivRight, STATE_MACHINE_NAME, INPUT_NAME);

    useEffect(() => {
        if (rivRightLevelInput) {
            rivRightLevelInput.value = value
        }
        if (rivLeftLevelInput) {
            rivLeftLevelInput.value = value
        }
    }, [value])


    useEffect(() => {
        if (rivRightLevelInput) {
            rivRightLevelInput.value = 100
        }
        if (rivLeftLevelInput) {
            rivLeftLevelInput.value = 100
        }
    }, [])

    return <>
        <div style={{...RiveStyle, left: 0}}>
            <RiveComponentLeft
                onMouseEnter={() => rivLeft && rivLeft.play()}
                onMouseLeave={() => rivLeft && rivLeft.pause()}
            />
        </div>
        <div style={{...RiveStyle, right: 0}}>
            <RiveComponentRight/>
        </div>
    </>
}


export default RiveComponent;
