import React from "react";
import Slider from "rc-slider";

interface SlideComponentProps {
    gradientColor: any
    rangeColor: any
    value: any
    setValue: any
    isMobile: any
}

const SlideComponent: React.FC<SlideComponentProps> = ({isMobile, gradientColor, rangeColor, value, setValue}) => {

    return <>
        <div className="mx-auto row justify-content-center mt-5 mb-2">
            <div style={{
                width: "auto",
                fontSize: "2.5vw",
                fontWeight: "bold",
                color: rangeColor,
                margin: "auto",
                marginTop: isMobile ? 3 : -2,
            }}>
                {Math.floor((64 * value) / 100)}GB
            </div>
            <div className="col" style={{width: "100%", margin: "0 2vw"}}>
                <Slider onChange={(nextValues: any) => {
                    // const position =  Math.floor((100 - nextValues) * 3.22);
                    // const tempValue = Math.floor(position / 3);
                    // setValue(tempValue > 100 ? 0 : 100 - tempValue)

                    // window.scrollTo(0, position)
                }} value={value} min={0} max={100} step={1}
                        railStyle={{
                            height: "2.5vw",
                            borderRadius: "2.5vw",
                            background: "#C6CEDE"
                        }}
                        trackStyle={{
                            height: "2.5vw",
                            borderRadius: "2.5vw",
                            background: gradientColor
                        }}
                        dotStyle={{height: "2.5vw"}}
                        activeDotStyle={{
                            height: "4.5vw",
                            width: "4.5vw",
                            borderColor: rangeColor,
                            background: rangeColor
                        }}
                        handleStyle={{
                            marginTop: "-1vw",
                            height: "4.5vw",
                            width: "4.5vw",
                            borderColor: rangeColor,
                            opacity: 0,
                            background: rangeColor
                        }}
                />
            </div>
            <div style={{
                width: "auto",
                fontSize: "2.5vw",
                fontWeight: "bold",
                color: "#C6CEDE",
                margin: "auto",
                marginTop: isMobile ? 3 : -2,
            }}>
                64GB
            </div>
            {/*<div>*/}
            {/*    <div className="mt-2" style={{textAlign: "center", color: "#0F0D1C", opacity: 0.5}}>swipe bar to left for make space on your phone then see the*/}
            {/*        result*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    </>
}


export default SlideComponent;
