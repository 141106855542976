import React, {useEffect, useState} from "react";
import logo from "../storage/images/logo.png";
import RiveComponent from "../components/rive/rive";
import SlideComponent from "../components/slide/slide";

export function Home() {
    const [value, setValue] = useState(100);

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    const isMobile = screenSize.dynamicWidth <= 768;

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        const tempValue = Math.floor(position / 3);
        setValue(tempValue > 100 ? 0 : 100 - tempValue)
    };

    let gradientColor;
    let rangeColor;
    if (value < 40) {
        gradientColor = "linear-gradient(90deg, #13AA08FF 8.35%, #198754 93.28%)"
        rangeColor = "rgb(19, 170, 8)"
    } else if (value >= 40 && value < 70) {
        gradientColor = "linear-gradient(90deg, rgb(252, 74, 26), rgb(247, 183, 51))"
        rangeColor = "rgb(247, 183, 51)"
    } else {
        gradientColor = "linear-gradient(90deg, #A93731 8.35%, #D3372D 93.28%)"
        rangeColor = "#A93731"
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    const paddingMobile = isMobile ? 250 : 300

    const relativeCondition = scrollPosition * (!isMobile ? 4.39 : 3.92) > screenSize.dynamicHeight + paddingMobile;
    const styleFixed: any = relativeCondition ? {marginTop: paddingMobile / 2} : {
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0
    }

    return (<>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <div>
                        <img src={logo} style={{width: "30pt", height: "30pt", marginRight: "5pt"}}/>
                        <a className="navbar-brand" href="#">Storage Cleaner</a>
                    </div>

                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" style={{color: "red"}}></span>
                        <span className="close" style={{fontSize: "40px"}}>&times;</span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="align-items-end navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link navbar-brand" aria-current="page" href="#features">Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link navbar-brand" href="#howitworks">How it Works</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="py-5">
                <div style={{
                    position: "relative",
                    height: !relativeCondition ? screenSize.dynamicHeight + paddingMobile : "auto"
                }}>
                    <div style={styleFixed}>
                        {value < 99 && <RiveComponent isMobile={isMobile} value={100 - value}/>}
                        <section id="hero" className="d-flex align-items-center container justify-content-center">
                            <div className="main-content container aos-init aos-animate" data-aos="fade-up">
                                <div className="row justify-content-center aos-init aos-animate mt-sm-5"
                                     data-aos="fade-up"
                                     data-aos-delay="150">

                                    <div className="col-xl-12 col-lg-8">
                                        <div className="slide-text">
                                            <span className="not-gradient-content"> Be part of digital cleaning,</span>
                                            <span className="gradient-content">make our world green together</span>
                                        </div>

                                        <div className="d-none d-sm-block ">
                                            <SlideComponent isMobile={isMobile} rangeColor={rangeColor}
                                                            gradientColor={gradientColor}
                                                            value={value} setValue={setValue}/>
                                        </div>
                                        <a href={'https://app.adjust.com/1azr22sa'} target={'_blank'}>
                                            <img src={require('./../storage/images/app-store.png')}
                                                 style={{width: "224px", height: "auto"}}/></a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="d-block d-sm-none">
                            <SlideComponent isMobile={isMobile} rangeColor={rangeColor} gradientColor={gradientColor}
                                            value={value} setValue={setValue}/>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-5 mt-md-0 container">
                    <div className="row mb-5 justify-content-center">
                        <h1 className="info-title">Did You Know?</h1>
                        <div className="col-12 col-sm-6">
                            <div className="text-start text-sm-center step-content">Remove all applications that you
                                haven’t
                                used for a
                                while or used
                                only a few times. Be honest and bold! Delete all those unnecessary forgotten old apps
                                and
                                games that
                                you have downloaded, but not yet even really tried. You won’t start using them later
                                either
                                – for
                                sure! They consume energy even when they are not in use and can consume monthly hundreds
                                of
                                megabytes of data for updates.
                            </div>
                        </div>
                    </div>

                    <div className="row" id="features">
                        <h1 className="mt-5 info-title">How to do a Digital Cleanup?</h1>
                        <div className="col-12 col-sm-6 mt-5">
                            <div className="text-sm-start mb-1">
                                <span className="gradient-content step-title">Step 1</span> <span
                                className="not-gradient-content step-title">Clean Your Smart Phone</span>
                            </div>
                            <div className="step-content text-start">Remove all applications that you haven’t used for a
                                while or used
                                only a few times. Be honest and bold! Delete all those unnecessary forgotten old apps
                                and
                                games that
                                you have downloaded, but not yet even really tried. You won’t start using them later
                                either
                                – for
                                sure! They consume energy even when they are not in use and can consume monthly hundreds
                                of
                                megabytes of data for updates.
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mt-5">
                            <div className="text-sm-start mb-1">
                                <span className="gradient-content step-title">Step 2</span> <span
                                className="not-gradient-content step-title">Clean Your Mailbox</span>
                            </div>
                            <div className="step-content text-start">Remove all applications that you haven’t used for a
                                while or used
                                only a few times. Be honest and bold! Delete all those unnecessary forgotten old apps
                                and
                                games that
                                you have downloaded, but not yet even really tried. You won’t start using them later
                                either
                                – for
                                sure! They consume energy even when they are not in use and can consume monthly hundreds
                                of
                                megabytes of data for updates.
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mt-5">
                            <div className="text-sm-start mb-1">
                                <span className="gradient-content step-title">Step 3</span> <span
                                className="not-gradient-content step-title">Clean Your PC</span>
                            </div>
                            <div className="step-content text-start">Remove all applications that you haven’t used for a
                                while or used
                                only a few times. Be honest and bold! Delete all those unnecessary forgotten old apps
                                and
                                games that
                                you have downloaded, but not yet even really tried. You won’t start using them later
                                either
                                – for
                                sure! They consume energy even when they are not in use and can consume monthly hundreds
                                of
                                megabytes of data for updates.
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mt-5">
                            <div className="text-sm-start mb-1">
                                <span className="gradient-content step-title">Step 4</span> <span
                                className="not-gradient-content step-title">Make New Arrangements</span>
                            </div>
                            <div className="step-content text-start">Remove all applications that you haven’t used for a
                                while or used
                                only a few times. Be honest and bold! Delete all those unnecessary forgotten old apps
                                and
                                games that
                                you have downloaded, but not yet even really tried. You won’t start using them later
                                either
                                – for
                                sure! They consume energy even when they are not in use and can consume monthly hundreds
                                of
                                megabytes of data for updates.
                            </div>
                        </div>
                    </div>


                    <div className="row mt-5" id="howitworks">
                        <h1 className="mt-5 info-title">What We’re Doing?</h1>
                        <div className="col-12 col-sm-6 mt-5 text-center text-sm-start align-self-center">
                            <img src={require('./../storage/images/large-files.png')}
                                 style={{"width": "48px", "height": "auto"}}/>
                            <div className="what-title">Large Files</div>
                            <div className="what-content">We identify your huge files for clean your phone.</div>
                        </div>
                        <div className="col-12 col-sm-6 mt-5 align-self-center">
                            <img src={require('./../storage/images/group-1.png')}
                                 style={{"width": "380px", "maxWidth": "100%", "height": "auto"}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="order-2 order-sm-1 col-12 col-sm-6 mt-5 align-self-center">
                            <img src={require('./../storage/images/group-2.png')}
                                 style={{"width": "350px", "maxWidth": "100%", "height": "auto"}}/>
                        </div>

                        <div
                            className="order-1 order-sm-2 col-12 col-sm-6 mt-5 text-center text-sm-start align-self-center">
                            <img src={require('./../storage/images/group-3.png')}
                                 style={{"width": "108px", "maxWidth": "100%", "height": "auto"}}/>
                            <div className="what-title">Similar Photo & Videos</div>
                            <div className="what-content">We identify your huge files for clean your phone.</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-6 mt-5 text-center text-sm-start align-self-center">
                            <img src={require('./../storage/images/group-5.png')}
                                 style={{"width": "108px", "maxWidth": "100%", "height": "auto"}}/>
                            <div className="what-title">Duplicate Photo & Videos</div>
                            <div className="what-content">We identify your huge files for clean your phone.</div>
                        </div>
                        <div className="col-12 col-sm-6 mt-5 align-self-center">
                            <img src={require('./../storage/images/group-7.png')}
                                 style={{"width": "358px", "maxWidth": "100%", "height": "auto"}}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="order-2 order-sm-1 col-12 col-sm-6 mt-5 align-self-center">
                            <img src={require('./../storage/images/group-6.png')}
                                 style={{"width": "340px", "maxWidth": "100%", "height": "auto"}}/>
                        </div>
                        <div
                            className="order-1 order-sm-2  col-12 col-sm-6 mt-5 text-center text-sm-start align-self-center">
                            <img src={require('./../storage/images/screenshot.png')}
                                 style={{"width": "48px", "maxWidth": "100%", "height": "auto"}}/>
                            <div className="what-title">Screenshots</div>
                            <div className="what-content">We identify your huge files for clean your phone..</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-6 mt-5 text-center text-sm-start align-self-center">
                            <img src={require('./../storage/images/auto-clean.png')}
                                 style={{"width": "48px", "maxWidth": "100%", "height": "auto"}}/>
                            <div className="what-title">Auto Clean</div>
                            <div className="what-content">We identify your huge files for clean your phone.</div>
                        </div>
                        <div className="col-12 col-sm-6 mt-5 align-self-center">
                            <img src={require('./../storage/images/group-4.png')}
                                 style={{"width": "380px", "maxWidth": "100%", "height": "auto"}}/>
                        </div>
                    </div>

                </div>


            </div>
        </>
    );
}
