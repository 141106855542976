export function Privacy() {
    return (<div style={{padding: 50}}>
                <p><strong>Online Privacy</strong><span>&nbsp;</span></p>
                <p><strong>Privacy Policy and Consent</strong></p>
                <p><span>&nbsp;</span></p>
                <p><span>Lunapark Medya A.S. (referred to as &ldquo;Free Up&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;) respects your privacy and is committed to protecting your privacy online.&nbsp; The purpose of this document (the &ldquo;Privacy Policy&rdquo;) is to explain to you how we use information that we have collected from and about you.&nbsp;</span></p>
            <p><span>This Privacy Policy applies to the services (the &ldquo;Services&rdquo;) provided through the Free Up website located at <a href={"http://www.getfreeup.ai"}>http://www.getfreeup.ai</a> (the &ldquo;Website&rdquo;) and all mobile platforms (the "App") made available by Free Up to deliver the Services. It does not apply to the practices of other companies that Free Up does not own or control nor does it apply to information of any kind provided to Free Up by advertisers or other business partners. Links to other websites are governed by their own policies.</span></p>
                <p><span>If this Privacy Policy is not acceptable to you, you should discontinue using the Services.</span></p>
                <p><strong>Information Collected By Automated Means</strong></p>
                <p><span>&nbsp;</span></p>
                <p><span>In this Policy, we refer to two types of user information: Personally Identifiable Information (sometimes referred to as &ldquo;PII&rdquo;) and non-Personally Identifiable Information.</span></p>
                <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><span>Personally Identifiable Information: Is information we collect that uniquely identifies you, the customer. This information may containname, physical address, e-mail address, telephone number, or a credit card number, as well as other information you may choose to share while interacting with our site and online services.</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>Free Up collects only the PII that you voluntarily provide to us, such as when you register to use the Services, set up a profile, create content, sign up for a special offer, enter a sweepstakes or promotion, or complete a survey. If you purchase products or subscribe for paid services, you might also be asked to provide your credit card and related information for billing purposes.</span></p>
                <p><span>You might also be asked for, or choose to provide, additional information about yourself that does not, itself, specifically identify you. This is non-Personally Identifiable Information. However, some information of this kind, such as your age, gender, geographic location and shopping preferences may be required (or merely requested) when you register for the Services, and may be associated with your PII.</span></p>
                <p><span>Non-Personal Information: Free Up may also automatically collect other types of non-Personally Identifiable Information as you use the Services, including technical information (such as your internet protocol (&ldquo;IP&rdquo;) address, mobile device ID, or browser type) and usage information (such as location data, preferred language, the parts of the Services you have visited, which links you have clicked and other information about how you interact with the Services). This information is anonymous, and is not associated with your PII, although it may be associated with other pieces of anonymous information about you. Free Up utilizes this data to analyze trends and understand customer needs while shopping and interacting on our site so that we can provide a better user experience.&nbsp;</span></p>
                <p><span>&nbsp;</span></p>
                <p><strong>Collection</strong></p>
                <p><span>&nbsp;</span></p>
                <p><span>You can browse our Website or App without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. If you choose to provide us with personal information, you consent to the transfer and storage of that information on our servers.We may collect and store the following personally identifiable information:</span></p>
                <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><span>- Email address, physical contact information, and shipping, billing, credit card info and other information you provide to purchase or ship an item</span></p>
                <p><span>- Clicks and page information such as the address (or URL ) of the web site that you came from before visiting our site, which pages you visit on our site, which browser you used to view our site and any search terms you have entered on our site</span></p>
                <p><span>- Customer service history including dispute resolution, correspondence through our sites, and correspondence sent to us</span></p>
                <p><span>- Other information from your interaction with our sites, apps, services, content and advertising, including computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address and standard web log information</span></p>
                <p><span>- Information you choose to provide through the linking of devices where you are actively choosing to share personal information to interact with an application or program we host on our site</span></p>
                <p><span>- Information you choose to allow us access to through social media channels&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><span>- Information from other companies, such as demographic and navigation data</span></p>
                <p><span>- As with any photo printing service, when you choose to print your photos, Free Up employees, subcontractors, and partners may see your photos while they are printed and packaged.</span></p>
                <h3><strong>Usage of the information</strong></h3>
                <p><span>&nbsp;</span></p>
                <p><span>Your Personally Identifiable Information may be used in one of the following ways:</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- To contact you regarding your account. These communications may include notices regarding changes to our policies, service updates, account management procedures and customer service transactional messages.</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- To e-mail you newsletters or tips about how to use the Services, inform you of product updates, or notify you about our own special offers or those of our third-party partners.</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- To bill your credit card or other specified form of payment, in accordance with the Terms of Use.</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>Non-Personally Identifiable Information may be used in one of the following ways:</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- To enable us to generate aggregate data about site traffic and site interaction</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- To customize your experience (your information helps us to better respond to your individual needs).</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- To show you advertisements most relevant to your demographic characteristics.</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- To make improvements to the Website and Services</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- To inform potential partners and advertisers about the size and characteristics of our audience</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>-&nbsp; To serve advertisement through third-parties:&nbsp; Free Up may use third party advertising networks to serve advertisements on the website.&nbsp; The cookies received with the banner advertisements served by these networks may be used to collect and build behavioral profiles by these companies to deliver targeted advertisements on our website and tracking referrals across different websites.</span></p>
                <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><strong>Other Ways We May Use or Disclose Information</strong></p>
                <p><span>&nbsp;</span></p>
                <p><span>Free Up does not sell your Personally Identifiable Information to outside parties. Our primary purpose in collecting personal information is to provide you with a safe, smooth, efficient, and customized experience.</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- Agents: We may employ other companies and individuals to perform functions on our behalf. These functions may include fulfilling orders, delivering packages, hosting sites from this app including the photo site, product reviews, developing pictures or photo products, sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, or processing credit card payments. They have access to personal information needed to perform their functions, but may not use it for other purposes.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- Business Transfers: As we continue to develop our business, we might sell or buy assets. In such transactions, customer information generally is one of the transferred business assets. Also, in the unlikely event that Free Up or substantially all of its assets are acquired, customer information will of course be one of the transferred assets and is permissible under law.</span></p>
                <p><span>&nbsp;</span></p>
                <p><span>- Protection of Free Up and Others: We release account and other personal information when we believe release is appropriate to comply with laws; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of Free Up, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</span></p>
                <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><strong>Third Party Service Providers:</strong></p>
                <p><span>&nbsp;</span></p>
                <p><span>We may work with other companies who place cookies or web beacons on our websites. These companies help operate our websites and provide you with additional products and service. Our app uses a third-party service provider to serve images. This service allows us to improve your overall customer experience.</span></p>
                <p><span>&nbsp;</span></p>
                <p><strong>Third Party Partnerships:</strong></p>
                <p><span>&nbsp;</span></p>
                <p><span>We may work with other companies who operate their own websites or mobile apps, to allow them to offer Free Up customers the ability to connect to Free Up services. These services give you more options for ways to use Free Up services from websites or apps that are not owned or operated by Lunapark Medya A.S.</span></p>
                <p><span>&nbsp;</span></p>
                <p><strong>Does Free Up collect information from children?&nbsp;</strong></p>
                <p><span>&nbsp;</span></p>
                <p><span>It is our intent not to sell to persons under the age of 18 and it is our policy not to do so. We sell children's products for purchase by adults. If you are under 18, you may use this website only with involvement of a parent or guardian. However Free Up does allow for the viewing of albums by minors above the age of 13 but purchases of products must be made by an adult.</span></p>
                <p><span>&nbsp;&nbsp;</span></p>
                <p><strong>Will this privacy policy change?</strong></p>
                <p><span>&nbsp;</span></p>
                <p><span>As our business changes, we will continue to implement new technologies and improve the services and features we provide. This privacy policy and the Terms of Use will be updated to reflect these changes. The use of information that we gather now is subject to the privacy policy in effect at the time of use. If there are changes or additions to this privacy policy, we will post those changes here or in other formats we deem acceptable, so that you will always know what information we collect online or offline, how we use it and what choices you have.</span></p>
                <p><span>It is your responsibility to review our privacy policy and Terms and Conditions for changes.</span></p>
                <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><strong>How To Contact Us</strong></p>
                <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><span>If you have questions about our Privacy Policy, contact us by email: </span><a href="mailto:support@getfreeup.ai"><span>support@getfreeup.ai</span></a></p>
                <p>&nbsp;</p>
                <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p><span>&nbsp;</span></p>
                <p>&nbsp;</p>
        </div>
    )
}
