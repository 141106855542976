import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './storage/styles/main.css'
import './App.css';

import 'rc-slider/assets/index.css';
import {Home} from "./pages/home";
import {Terms} from "./pages/terms";
import {Privacy} from "./pages/privacy";

function App() {
    return <Router>
        <Switch>
            <Route exact path="/">
                <Home/>
            </Route>
            <Route exact path="/terms">
                <Terms/>
            </Route>
            <Route exact path="/privacy">
                <Privacy/>
            </Route>
        </Switch>
    </Router>
}

export default App;
